var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DebtorDataPay" }, [
    _c("label", { staticClass: "fs-18 fw-med" }, [_vm._v("Days to Pay")]),
    _c("div", { staticClass: "DebtorDataPay__table mt-23" }, [
      _vm._m(0),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "DebtorDataPay__pay-wrapper row" }, [
          _c(
            "label",
            { staticClass: "DebtorDataPay__pay-wrapper-column fs-15" },
            [_vm._v("Internal")]
          ),
          _c(
            "label",
            { staticClass: "DebtorDataPay__pay-wrapper-column fc-light fs-14" },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.debtor.internal_days_to_pay_60) +
                  "\n          (" +
                  _vm._s(
                    _vm.debtor.internal_invoices_60
                      ? _vm.debtor.internal_invoices_60
                      : 0
                  ) +
                  ")\n        "
              )
            ]
          ),
          _c(
            "label",
            { staticClass: "DebtorDataPay__pay-wrapper-column fc-light fs-14" },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.debtor.internal_days_to_pay_90) +
                  "\n          (" +
                  _vm._s(
                    _vm.debtor.internal_invoices_90
                      ? _vm.debtor.internal_invoices_60
                      : 0
                  ) +
                  ")\n        "
              )
            ]
          ),
          _c(
            "label",
            { staticClass: "DebtorDataPay__pay-wrapper-column fs-14" },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.debtor.internal_days_to_pay_total) +
                  "\n          (" +
                  _vm._s(
                    _vm.debtor.internal_invoices_total
                      ? _vm.debtor.internal_invoices_total
                      : 0
                  ) +
                  ")\n        "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "DebtorDataPay__pay-wrapper row" }, [
          _c(
            "label",
            { staticClass: "DebtorDataPay__pay-wrapper-column fs-15" },
            [_vm._v("External")]
          ),
          _c(
            "label",
            { staticClass: "DebtorDataPay__pay-wrapper-column fc-light fs-14" },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.debtor.external_days_to_pay_60) +
                  "\n          (" +
                  _vm._s(
                    _vm.debtor.external_invoices_60
                      ? _vm.debtor.external_invoices_60
                      : 0
                  ) +
                  ")\n        "
              )
            ]
          ),
          _c(
            "label",
            { staticClass: "DebtorDataPay__pay-wrapper-column fc-light fs-14" },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.debtor.external_days_to_pay_90) +
                  "\n          (" +
                  _vm._s(
                    _vm.debtor.external_invoices_90
                      ? _vm.debtor.external_invoices_90
                      : 0
                  ) +
                  ")\n        "
              )
            ]
          ),
          _c(
            "label",
            { staticClass: "DebtorDataPay__pay-wrapper-column fs-14" },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.debtor.external_days_to_pay_total) +
                  "\n          (" +
                  _vm._s(
                    _vm.debtor.external_invoices_total
                      ? _vm.debtor.external_invoices_total
                      : 0
                  ) +
                  ")\n        "
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "DebtorDataPay__head-wrapper row row--justify-between no-wrap"
      },
      [
        _c(
          "label",
          {
            staticClass:
              "DebtorDataPay__head-lbl fc-light fs-12 uppercase mr-16"
          },
          [_vm._v("TYPE")]
        ),
        _c(
          "label",
          {
            staticClass:
              "DebtorDataPay__head-lbl fc-light fs-12 uppercase mr-16"
          },
          [_vm._v("LAST 60 DAYS")]
        ),
        _c(
          "label",
          {
            staticClass:
              "DebtorDataPay__head-lbl fc-light fs-12 uppercase mr-16"
          },
          [_vm._v("LAST 90 DAYS")]
        ),
        _c(
          "label",
          { staticClass: "DebtorDataPay__head-lbl fc-light fs-12 uppercase" },
          [_vm._v("ALL TIME")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }