var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DebtorDataGeneral" },
    [
      _c("transition-group", { attrs: { name: "Transition__fade" } }, [
        _vm.requestsProgress.aging
          ? _c(
              "div",
              {
                key: "aging",
                staticClass:
                  "DebtorDataGeneral__aging-content row row--align-center row--justify-between row--wrap"
              },
              [
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12" }, [
                    _vm._v("0-30")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "DebtorDataGeneral__aging-amount fs-24 mt-1"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.agingValueText(_vm.aging.cat0to30)) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "fs-16" }, [
                        _vm._v(
                          "\n            ." +
                            _vm._s(
                              _vm.agingValueText(_vm.aging.cat0to30, true)
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass:
                        "DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.aging.cat0to30Percent) +
                          "%\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12" }, [
                    _vm._v("31-45")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "DebtorDataGeneral__aging-amount fs-24 mt-1"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.agingValueText(_vm.aging.cat31to45)) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "fs-16" }, [
                        _vm._v(
                          "\n            ." +
                            _vm._s(
                              _vm.agingValueText(_vm.aging.cat31to45, true)
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass:
                        "DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.aging.cat31to45Percent) +
                          "%\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12" }, [
                    _vm._v("46-60")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "DebtorDataGeneral__aging-amount fs-24 mt-1"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.agingValueText(_vm.aging.cat46to60)) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "fs-16" }, [
                        _vm._v(
                          "\n            ." +
                            _vm._s(
                              _vm.agingValueText(_vm.aging.cat46to60, true)
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass:
                        "DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.aging.cat46to60Percent) +
                          "%\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12" }, [
                    _vm._v("61-90")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "DebtorDataGeneral__aging-amount fs-24 mt-1"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.agingValueText(_vm.aging.cat61to90)) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "fs-16" }, [
                        _vm._v(
                          "\n            ." +
                            _vm._s(
                              _vm.agingValueText(_vm.aging.cat61to90, true)
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass:
                        "DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.aging.cat61to90Percent) +
                          "%\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12" }, [
                    _vm._v("91+")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "DebtorDataGeneral__aging-amount fs-24 mt-1"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.agingValueText(_vm.aging.cat91Plus)) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "fs-16" }, [
                        _vm._v(
                          "\n            ." +
                            _vm._s(
                              _vm.agingValueText(_vm.aging.cat91Plus, true)
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass:
                        "DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.aging.cat91PlusPercent) +
                          "%\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-blue fs-16" }, [
                    _vm._v("Total")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass:
                        "DebtorDataGeneral__aging-amount fc-light fs-24 mt-1"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.agingValueText(_vm.aging.catTotal)) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "fc-light fs-16" }, [
                        _vm._v(
                          "\n            ." +
                            _vm._s(
                              _vm.agingValueText(_vm.aging.catTotal, true)
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "DebtorDataGeneral__employed-lbl column column--width-auto"
                    },
                    [
                      _c("label", { staticClass: "fc-green fs-22" }, [
                        _vm._v("$" + _vm._s(_vm.aging.catTotal))
                      ]),
                      _c("label", { staticClass: "fc-green fs-11 uppercase" }, [
                        _vm._v("Employed")
                      ])
                    ]
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm.requestsProgress.aging
          ? _c("div", { key: "pay" }, [_c("debtor-data-pay")], 1)
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }