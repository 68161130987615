<template>
  <div class="DebtorDataGeneral">
    <transition-group name="Transition__fade">
      <div
        v-if="requestsProgress.aging"
        class="DebtorDataGeneral__aging-content row row--align-center row--justify-between row--wrap"
        key="aging"
      >
        <div class="column column--width-auto">
          <label class="fc-light fs-12">0-30</label>
          <label class="DebtorDataGeneral__aging-amount fs-24 mt-1">
            {{ agingValueText(aging.cat0to30) }}
            <span class="fs-16">
              .{{ agingValueText(aging.cat0to30, true) }}
            </span>
          </label>
          <label class="DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6">
            {{ aging.cat0to30Percent }}%
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12">31-45</label>
          <label class="DebtorDataGeneral__aging-amount fs-24 mt-1">
            {{ agingValueText(aging.cat31to45) }}
            <span class="fs-16">
              .{{ agingValueText(aging.cat31to45, true) }}
            </span>
          </label>
          <label class="DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6">
            {{ aging.cat31to45Percent }}%
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12">46-60</label>
          <label class="DebtorDataGeneral__aging-amount fs-24 mt-1">
            {{ agingValueText(aging.cat46to60) }}
            <span class="fs-16">
              .{{ agingValueText(aging.cat46to60, true) }}
            </span>
          </label>
          <label class="DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6">
            {{ aging.cat46to60Percent }}%
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12">61-90</label>
          <label class="DebtorDataGeneral__aging-amount fs-24 mt-1">
            {{ agingValueText(aging.cat61to90) }}
            <span class="fs-16">
              .{{ agingValueText(aging.cat61to90, true) }}
            </span>
          </label>
          <label class="DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6">
            {{ aging.cat61to90Percent }}%
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-light fs-12">91+</label>
          <label class="DebtorDataGeneral__aging-amount fs-24 mt-1">
            {{ agingValueText(aging.cat91Plus) }}
            <span class="fs-16">
              .{{ agingValueText(aging.cat91Plus, true) }}
            </span>
          </label>
          <label class="DebtorDataGeneral__percent-lbl bg-green fc-white fs-12 mt-6">
            {{ aging.cat91PlusPercent }}%
          </label>
        </div>
        <div class="column column--width-auto">
          <label class="fc-blue fs-16">Total</label>
          <label class="DebtorDataGeneral__aging-amount fc-light fs-24 mt-1">
            {{ agingValueText(aging.catTotal) }}
            <span class="fc-light fs-16">
              .{{ agingValueText(aging.catTotal, true) }}
            </span>
          </label>
          <div class="DebtorDataGeneral__employed-lbl column column--width-auto">
            <label class="fc-green fs-22">${{ aging.catTotal }}</label>
            <label class="fc-green fs-11 uppercase">Employed</label>
          </div>
        </div>
      </div>
      <div
        v-if="requestsProgress.aging"
        key="pay"
      >
        <debtor-data-pay />
      </div>
    </transition-group>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import { Invoice } from '../../../../utils/api'
// Components
import DebtorDataPay from './debtor-data-pay.vue'

export default {
  name: 'DebtorDataGeneral',

  components: {
    DebtorDataPay,
  },

  async created () {
    this.progressStart()
    await this.getAgingData()
    this.progressFinish()
  },

  data () {
    return {
      aging: {
        cat0to30: null,
        cat0to30Percent: null,
        cat31to45: null,
        cat31to45Percent: null,
        cat46to60: null,
        cat46to60Percent: null,
        cat61to90: null,
        cat61to90Percent: null,
        cat91Plus: null,
        cat91PlusPercent: null,
        catTotal: null,
      },
      approvedInvoices: [],
      requestsProgress: {
        aging: false,
        pay: false,
      },
    }
  },

  computed: {
    debtor () {
      return this.$store.getters.debtor
    },
  },

  methods: {
    agingValueText (age, decimal = false) {
      return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        .format(age)
        .split('.')[decimal ? 1 : 0]
    },

    async getAgingData () {
      try {
        // DIVIDE approved invoice ammounts into aging categories and total each category
        // 'cat' is short for 'category'
        const cat0to30Total = await this.getCat0to30Total()
        const cat31to45Total = await this.getCat31to45Total()
        const cat46to60Total = await this.getCat46to60Total()
        const cat61to90Total = await this.getCat61to90Total()
        const cat91PlusTotal = await this.getCat91PlusTotal()
        const catTotal = await this.getCatTotal()

        const cat0to30Percent = (cat0to30Total / catTotal * 100).toFixed(2)
        const cat31to45Percent = (cat31to45Total / catTotal * 100).toFixed(2)
        const cat46to60Percent = (cat46to60Total / catTotal * 100).toFixed(2)
        const cat61to90Percent = (cat61to90Total / catTotal * 100).toFixed(2)
        const cat91PlusPercent = (cat91PlusTotal / catTotal * 100).toFixed(2)

        this.$set(this.aging, 'cat0to30', cat0to30Total)
        this.$set(this.aging, 'cat0to30Percent', isNaN(cat0to30Percent) ? 0 : cat0to30Percent)
        this.$set(this.aging, 'cat31to45', cat31to45Total)
        this.$set(this.aging, 'cat31to45Percent', isNaN(cat31to45Percent) ? 0 : cat31to45Percent)
        this.$set(this.aging, 'cat46to60', cat46to60Total)
        this.$set(this.aging, 'cat46to60Percent', isNaN(cat46to60Percent) ? 0 : cat46to60Percent)
        this.$set(this.aging, 'cat61to90', cat61to90Total)
        this.$set(this.aging, 'cat61to90Percent', isNaN(cat61to90Percent) ? 0 : cat61to90Percent)
        this.$set(this.aging, 'cat91Plus', cat91PlusTotal)
        this.$set(this.aging, 'cat91PlusPercent', isNaN(cat91PlusPercent) ? 0 : cat91PlusPercent)
        this.$set(this.aging, 'catTotal', catTotal)

        this.$set(this.requestsProgress, 'aging', true)
      }
      catch (error) {
        this.CError(error)
        this.requestFailure({ message: 'There was an issue setting up the aging data' })
      }
    },

    async getCat0to30Total () {
      const to = moment().startOf('day').subtract(30, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          debtor_id: this.debtor.id,
          gt___approved_date: to,
          status: 'approved'
        })).data

        return result.facets_stats
          ? (result.facets_stats.client_total_to_pay_from_invoice.sum / 100)
          : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Data General "getCat0to30Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCat31to45Total () {
      const from = moment().startOf('day').subtract(31, 'days').toDate()
      const to = moment().startOf('day').subtract(45, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          debtor_id: this.debtor.id,
          gt___approved_date: to,
          lt___approved_date: from,
          status: 'approved'
        })).data

        return result.facets_stats
          ? (result.facets_stats.client_total_to_pay_from_invoice.sum / 100)
          : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Data General "getCat31to45Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            from,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCat46to60Total () {
      const from = moment().startOf('day').subtract(46, 'days').toDate()
      const to = moment().startOf('day').subtract(60, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          debtor_id: this.debtor.id,
          gt___approved_date: to,
          lt___approved_date: from,
          status: 'approved'
        })).data

        return result.facets_stats
          ? (result.facets_stats.client_total_to_pay_from_invoice.sum / 100)
          : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Data General "getCat46to60Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            from,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCat61to90Total () {
      const from = moment().startOf('day').subtract(61, 'days').toDate()
      const to = moment().startOf('day').subtract(90, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          debtor_id: this.debtor.id,
          gt___approved_date: to,
          lt___approved_date: from,
          status: 'approved'
        })).data

        return result.facets_stats
          ? (result.facets_stats.client_total_to_pay_from_invoice.sum / 100)
          : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Data General "getCat61to90Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            from,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCat91PlusTotal () {
      const from = moment().startOf('day').subtract(91, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          debtor_id: this.debtor.id,
          lt___approved_date: from,
          status: 'approved'
        })).data

        return result.facets_stats
          ? (result.facets_stats.client_total_to_pay_from_invoice.sum / 100)
          : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Data General "getCat91PlusTotal"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            from,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },

    async getCatTotal () {
      let result
      try {
        result = (await Invoice.queryList({
          debtor_id: this.debtor.id,
          status: 'approved'
        })).data

        return result.facets_stats
          ? (result.facets_stats.client_total_to_pay_from_invoice.sum / 100)
          : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Data General "getCatTotal"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting total values' })
      }
    },
  },
}
</script>

<style lang="sass">
.DebtorDataGeneral

  &__aging-amount
    line-height: rem(44px)

  &__aging-content
    margin-top: rem(38px)

    .column--width-auto
      margin-right: rem(42px)

      &:last-child
        margin-right: 0

  &__employed-lbl
    background-color: #E4FCE8
    border-radius: $border-radius
    padding: rem(4px) rem(18px)

  &__percent-lbl
    display: inline-block
    padding: rem(3px) rem(7px)
</style>