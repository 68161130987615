<template>
  <div class="DebtorDataPay">
    <label class="fs-18 fw-med">Days to Pay</label>
    <div class="DebtorDataPay__table mt-23">
      <div class="DebtorDataPay__head-wrapper row row--justify-between no-wrap">
        <label class="DebtorDataPay__head-lbl fc-light fs-12 uppercase mr-16">TYPE</label>
        <label class="DebtorDataPay__head-lbl fc-light fs-12 uppercase mr-16">LAST 60 DAYS</label>
        <label class="DebtorDataPay__head-lbl fc-light fs-12 uppercase mr-16">LAST 90 DAYS</label>
        <label class="DebtorDataPay__head-lbl fc-light fs-12 uppercase">ALL TIME</label>
      </div>
      <div class="column">
        <div class="DebtorDataPay__pay-wrapper row">
          <label class="DebtorDataPay__pay-wrapper-column fs-15">Internal</label>
          <label class="DebtorDataPay__pay-wrapper-column fc-light fs-14">
            {{ debtor.internal_days_to_pay_60 }}
            ({{ debtor.internal_invoices_60 ? debtor.internal_invoices_60 : 0 }})
          </label>
          <label class="DebtorDataPay__pay-wrapper-column fc-light fs-14">
            {{ debtor.internal_days_to_pay_90 }}
            ({{ debtor. internal_invoices_90 ? debtor.internal_invoices_60 : 0 }})
          </label>
          <label class="DebtorDataPay__pay-wrapper-column fs-14">
            {{ debtor.internal_days_to_pay_total }}
            ({{ debtor.internal_invoices_total ? debtor.internal_invoices_total : 0 }})
          </label>
        </div>
        <div class="DebtorDataPay__pay-wrapper row">
          <label class="DebtorDataPay__pay-wrapper-column fs-15">External</label>
          <label class="DebtorDataPay__pay-wrapper-column fc-light fs-14">
            {{ debtor.external_days_to_pay_60 }}
            ({{ debtor.external_invoices_60 ? debtor.external_invoices_60 : 0 }})
          </label>
          <label class="DebtorDataPay__pay-wrapper-column fc-light fs-14">
            {{ debtor.external_days_to_pay_90 }}
            ({{ debtor. external_invoices_90 ? debtor. external_invoices_90 : 0 }})
          </label>
          <label class="DebtorDataPay__pay-wrapper-column fs-14">
            {{ debtor.external_days_to_pay_total }}
            ({{ debtor.external_invoices_total ? debtor.external_invoices_total : 0 }})
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DebtorDataPay',

  computed: {
    debtor () {
      return this.$store.getters.debtor
    },
  }
}
</script>

<style lang="sass">
.DebtorDataPay
  $block: &
  margin-top: rem(33px)

  &__head-lbl
    flex: 1 0 0

    &:last-child
      flex: 0 0 rem(100px)

  &__head-wrapper
    border-radius: rem(4px) rem(4px) 0 0
    padding: rem(10px) rem(27px)

  &__pay-wrapper
    border-top: $border
    padding: rem(25px) rem(27px)

  &__pay-wrapper-column
    flex: 1 0 0

    &:last-child
      flex: 0 0 rem(100px)

  &__table
    background-color: $white
    border-radius: $border-radius
    box-shadow: $container-shadow
    width: 100%
</style>